import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';
// Create your functional component:

function CallBack() {

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const code = { code: searchParams.get("code") };
    const headers = {
        "Content-Type": "application/json"
    };



    axios.post('https://socials-api.beararena.com/discord-code-grant', code, { headers })
        .then(response => {
            console.log(response)
            localStorage.setItem('discord', JSON.stringify(response.data));
            navigate("/");
        }).catch((error) => {
            // Error
            localStorage.removeItem('discord');
            console.log(error);
            window.alert(error);
            navigate("/");
        });

    return (
        <div>
            <p style={{ color: 'black' }}>Connecting please wait...</p>
        </div>
    );
}


export default CallBack;