import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppBar, Toolbar, useMediaQuery, useTheme, CssBaseline, Box, Button, ThemeProvider, createTheme, styled, Typography, Container } from '@mui/material';
import Home from './components/Home';
import Callback from './components/Callback';
import NotFound from './components/NotFound';

// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#bb86fc',
    },
    background: {
      default: '#121212',
      paper: '#121212',
    },
  },
});

// Custom styled button with hover effects and gradient
const PlayNowButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#6C5CE7',
  color: '#ffffff',
  padding: theme.spacing(1, 3),
  fontSize: '1rem',
  fontWeight: 'bold',
  transition: 'transform 0.2s ease-in-out, background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#4834d4',
    transform: 'scale(1.05)',
    boxShadow: '0 6px 20px rgba(0,0,0,0.19)',
  },
  backgroundImage: 'linear-gradient(45deg, #6C5CE7 30%, #a29bfe 90%)',
  borderRadius: '20px',
  border: '0',
  boxShadow: '0 3px 5px 2px rgba(108, 92, 231, .3)',
}));

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBar
            position="static"
            sx={{ borderBottom: `1px solid ${darkTheme.palette.divider}`, backgroundColor: darkTheme.palette.background.paper }}
          >
            <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="https://play.beararena.com/logo/Bear_ArenaLogo.png"
                  alt="Logo"
                  style={{ marginRight: theme.spacing(2), height: isMobile ? '50px' : '100px' }}
                />
              </Box>

              <Box>
                <PlayNowButton href="https://play.beararena.com">
                  Play Now!
                </PlayNowButton>
                <Button href="https://discord.gg/beararena" color="primary" sx={{ my: 1, mx: 1.5 }}>
                  Discord
                </Button>
                <Button href="https://twitter.com/beararenagame" color="primary" sx={{ my: 1, mx: 1.5 }}>
                  Twitter
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          {/* Main content */}
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/callback" element={<Callback />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
          {/* Footer */}
          <Box component="footer" sx={{ py: 3, px: 2, backgroundColor: darkTheme.palette.background.paper }}>
            <Container maxWidth="sm">
              <Typography variant="body1" color="text.secondary" align="center">
                © {new Date().getFullYear()} Bear Arena. All rights reserved.
              </Typography>
            </Container>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
