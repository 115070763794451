import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
        <div>
          <h2>Not Found</h2>
        </div>
    );
  }
}

export default NotFound;