import React, { Component, useState } from 'react';
import { Button, Card, Typography, Container, Box, SvgIcon, Grid, Divider, styled, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

import axios from 'axios';
import { ethers } from "ethers";
import MetaMaskOnboarding from "@metamask/onboarding";
const headers = {
    "Content-Type": "application/json"
};
declare const window: any;
const discordLoginURL = 'https://discord.com/api/oauth2/authorize?client_id=1202014057863847936&response_type=code&redirect_uri=https%3A%2F%2Fsocials.beararena.com%2Fcallback&scope=identify+email'
const discordColor = '#5865F2';



// Custom Box with a subtle background gradient
const SuccessBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    background: 'linear-gradient(to right, #6a11cb 0%, #2575fc 100%)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    margin: theme.spacing(2, 0),
}));

// Custom icon styles
const CustomIcon = styled('span')(({ theme }) => ({
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    display: 'inline-flex',
}));

const DiscordIcon = () => (
    <SvgIcon>
        <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" fill="white"></path>
    </SvgIcon>
);

const MetaMaskIcon = () => (
    <SvgIcon>
        <path d="M12,2A10,10 0 1,0 22,12A10,10 0 0,0 12,2Z" />
    </SvgIcon>
);

// Define custom styles
const styles = {
    card: {
        background: 'linear-gradient(145deg, #1a1b1e, #222529)',
        color: '#ffffff',
        padding: 3,
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(5px)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
    },
    button: {
        backgroundImage: 'linear-gradient(45deg, #6e8efb, #c471ed)',
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            backgroundImage: 'linear-gradient(45deg, #c471ed, #6e8efb)',
        },
    },
    divider: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
};


function Home() {
    const theme = useTheme();
    const [isDiscordConnected, setIsDiscordConnected] = useState(false);

    const [isWalletSigned, setisWalletSigned] = useState(false);
    const [connectedDiscordAccount, setConnectedDiscordAccount] = useState<any>();
    const [mess, setMessage] = useState("");
    const [address, setAddress] = useState<any>("");
    if (!isDiscordConnected) {
        try {
            let storageDiscordItem = localStorage.getItem('discord');
            let localDiscordData = JSON.parse(((storageDiscordItem ? storageDiscordItem.toString() : '')));
            console.log('localDiscordData', localDiscordData);
            checkDiscordLogin(localDiscordData);
            let storageWalletItem = localStorage.getItem('gocwallet');
            let localWalletData = JSON.parse((storageWalletItem ? storageWalletItem.toString() : ''));
            console.log('localWalletData', localWalletData);
        } catch {
            //window.alert('Discord Connection Error');
        }
    }


    function checkDiscordLogin(discordData: any) {
        axios.post('https://socials-api.beararena.com/discord-check-login', { 'discordData': discordData }, { headers })
            .then(response => {
                localStorage.setItem('discord', JSON.stringify(response.data));

                console.log(response.data.data.me.user.username)
                setConnectedDiscordAccount(response.data.data.me.user);
                setMessage(response.data.data.me.user.id);
                setIsDiscordConnected(true);
                handleLogin()
            }).catch((error) => {
                // Error
                localStorage.removeItem('discord');
                console.log(error);
                window.alert(error);
                setIsDiscordConnected(false);
            });
    }

    function verifySignedMessage(discordData: any, address: string, signature: string, chain_id: string) {
        axios.post('https://socials-api.beararena.com/verify-signed-message', { 'discordData': discordData, 'address': address, 'signature': signature, 'chain_id': chain_id }, { headers })
            .then(response => {
                console.log('verifysignedmessage', response)
                localStorage.setItem('discord',(JSON.stringify({ 'data': response.data.data.discordData })));
                setConnectedDiscordAccount(response.data.data.discordData.me.user);
                setMessage(response.data.data.discordData.me.user.id);
                setIsDiscordConnected(true);
                localStorage.setItem('gocwallet', (JSON.stringify({ 'address': response.data.data.address, 'chain_id': response.data.data.chain_id })));
                setisWalletSigned(true);
            }).catch((error) => {
                // Error
                localStorage.removeItem('gocwallet');
                console.log(error);
                window.alert(error);
            });
    }




    //Metamask
    const [logged, setLogged] = useState(false);
    const [account, setAccount] = useState(null);
    const [balance, setBalance] = useState("");
    //const forwarderOrigin = "http://localhost:3000";
    const forwarderOrigin = "https://socials.beararena.com";
    const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
    const { utils } = require("ethers");
    const [signature, setSignature] = useState("");

    const [verifyAddress, setVerifyAddress] = useState("");
    const [verificationStatus, setVerificationStatus] = useState("");
    const handleLogin = () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            console.log("MetaMask Here!");
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((result: any) => {
                    console.log(result);
                    setLogged(true);
                    setAccount(utils.getAddress(result[0]));
                })
                .catch((error: any) => {
                    console.log("Could not detect Account");
                });
        } else {
            window.alert('Please install Metamask to use this app.');
            console.log("Need to install MetaMask");
            onboarding.startOnboarding();
        }
    };

    const handleSign = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const message = mess;
        const signer = await provider!.getSigner();
        const signature = await signer.signMessage(message);
        const address = await signer.getAddress();
        const chain_id = await signer.getChainId();
        setSignature(signature);
        setAddress(address);
        console.log("sig : " + signature);
        let storageDiscordItem = localStorage.getItem('discord');
        let localDiscordData = JSON.parse(((storageDiscordItem ? storageDiscordItem.toString() : '')));
        verifySignedMessage(localDiscordData, address, signature, chain_id.toString());
    };


    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            <Card sx={styles.card}>
                <Box textAlign="center" sx={{ marginBottom: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        Welcome to Bear Arena Socials
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" style={{ color: '#c2c2c2' }}>
                        Connect your Discord and MetaMask wallet to get started
                    </Typography>
                </Box>

                <Divider sx={styles.divider} />

                {!isDiscordConnected ? (
                    <Button
                        variant="contained"
                        href={discordLoginURL}
                        fullWidth
                        startIcon={<DiscordIcon />}
                        sx={styles.button}
                    >
                        Login with Discord
                    </Button>
                ) : (
                    <Box textAlign="center">
                        <Typography variant="h6" gutterBottom>
                            Discord Connected!
                        </Typography>
                        <Typography variant="subtitle1">
                            {connectedDiscordAccount.username} #{connectedDiscordAccount.discriminator}
                        </Typography>
                    </Box>
                )}

                <Divider sx={{ my: 2, ...styles.divider }} />

                {isDiscordConnected && (
                    <Grid container spacing={2} justifyContent="center">
                        {!logged ? (
                            <Grid item>
                                <Button variant="contained" onClick={handleLogin} sx={styles.button}>
                                    Connect with MetaMask
                                </Button>
                            </Grid>
                        ) : (
                            !isWalletSigned ? (
                                <Grid item>
                                    <Button variant="contained" onClick={handleSign} fullWidth sx={styles.button}>
                                        Sign Message with MetaMask
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <SuccessBox>
                                        <Typography variant="h6" gutterBottom>
                                            <CustomIcon>
                                                <CheckCircleOutlineIcon style={{ color: theme.palette.success.main }} />
                                            </CustomIcon>
                                            Success!
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <CustomIcon>
                                                <AccountBalanceWalletOutlinedIcon style={{ color: theme.palette.info.main }} />
                                            </CustomIcon>
                                            Connected Wallet: {account}
                                        </Typography>
                                    </SuccessBox>
                                    <Button
                                        variant="contained"
                                        href="https://play.beararena.com"
                                        sx={{ marginTop: 2, ...styles.button }}
                                        fullWidth
                                    >
                                        Play Now!
                                    </Button>
                                </Grid>
                            )
                        )}
                    </Grid>
                )}
            </Card>
        </Container>
    );
};


export default Home;